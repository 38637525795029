<div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="#">
            <img src="https://commerce.fra1.cdn.digitaloceanspaces.com/public-images/icon.png"
                class="logoClass d-inline-block align-top" alt="">
            Thenga-Lula
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <div>
                        <a class="nav-link" href="/privacyPolicy" (click)="togglePdf()">Privacy</a>
                    </div>
                </li>
                <li class="nav-item">
                    <div>
                        <a class="nav-link" href="/termsOfUse" (click)="togglePdf()">T&Cs</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://wa.me/message/PBZ6CTRZD4KKE1">Support</a>
                </li>
<!--                <li class="nav-item dropdown">-->
<!--                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"-->
<!--                        aria-haspopup="true" aria-expanded="false">-->
<!--                        Order Events-->
<!--                    </a>-->
<!--                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->
<!--                        <a class="dropdown-item" href="/orderDetails">Past Orders</a>-->
<!--                        <a class="dropdown-item" href="/driver">Driver Orders</a>-->
<!--                    </div>-->
<!--                </li>-->
            </ul>
            <button class="btn loginButton btn-sm text-white" (click)="dashboardRedirect()" type="submit">Portal</button>
        </div>
    </nav>
</div>
