import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {HomeComponent} from './pages/home/home.component';
import {TermsOfUsePdfComponent} from './pages/terms-of-use-pdf/terms-of-use-pdf.component';
import {PdfViewerComponent} from './pages/pdf-viewer/pdf-viewer.component';

const routes: Routes = [

  { path: '', component: HomeComponent },
  // { path: 'blog', component: BlogComponent },
  // { path: 'blog-details', component: BlogdetailsComponent },
  // { path: 'ex-deals', component: ExdealsComponent },
  // { path: 'about', component: AboutComponent },
  // { path: 'restaurant', component: RestaurantComponent },
  // { path: 'restaurant-style-1', component: Restaurantstyle1Component },
  // { path: 'add-restaurant', component: AddrestaurantComponent },
  // { path: 'listview', component: ListviewComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  // { path: 'checkout', component: CheckoutComponent },
  // { path: 'orderDetails', component: OrderdetailsComponent },
  // { path: 'geolocator', component: GeolocatorComponent },
  // { path: 'contact', component: ContactComponent },
  { path: 'termsOfUse', component: TermsOfUsePdfComponent },
  { path: 'privacyPolicy', component: PdfViewerComponent },
  { path: 'error-page', component: ErrorPageComponent },
  { path: '**', component: ErrorPageComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
