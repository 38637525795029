import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-use-pdf',
  templateUrl: './terms-of-use-pdf.component.html',
  styleUrls: ['./terms-of-use-pdf.component.css']
})
export class TermsOfUsePdfComponent implements OnInit {

  pdfSrc = 'https://commerce.fra1.cdn.digitaloceanspaces.com/documents/termsOfUse.pdf';

  constructor() { }

  ngOnInit(): void {
  }

}
