<app-navbar *ngIf="!preview"></app-navbar>
<div *ngIf="preview">
  <body style="margin:0px;padding:0px;overflow:hidden">
  <iframe [src]="pdfSrc | safeUrl" frameborder="0"
          style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
          height="100%" width="100%"></iframe>
  </body>
</div>

<div class="container my-5" *ngIf="!preview">
  <h1 class="mb-4">Privacy Policy</h1>

  <section>
    <h2>Introduction</h2>
    <p>Thenga-Lula is committed to protecting your privacy. This Privacy Policy explains how we collect,
      use, disclose, and safeguard your information when you use our mobile application ("App").
      Please read this Privacy Policy carefully. By using the App, you consent to the data practices
      described in this policy.</p>
  </section>

  <section>
    <h2>Collection of Information</h2>

    <h3>Personal Information</h3>
    <p>We may collect personal information from you, such as your name, email address, phone number,
      delivery address, and payment information when you:</p>
    <ul>
      <li>Register for an account</li>
      <li>Place an order</li>
      <li>Contact customer support</li>
    </ul>

    <h3>Automatically Collected Information</h3>
    <p>When you use the App, we automatically collect certain information, including:</p>
    <ul>
      <li>Device information (e.g., IP address, device type, operating system)</li>
      <li>Usage data (e.g., time and date of access, pages viewed, and interactions)</li>
    </ul>
  </section>

  <section>
    <h2>Use of Information</h2>
    <p>We use the collected information to:</p>
    <ul>
      <li>Provide, operate, and maintain our services</li>
      <li>Process and manage orders</li>
      <li>Improve, personalize, and expand our services</li>
      <li>Communicate with you, including for customer support</li>
      <li>Process transactions and send related information</li>
      <li>Monitor and analyze usage and trends to improve your experience</li>
    </ul>
  </section>

  <section>
    <h2>Disclosure of Information</h2>
    <p>We may share your information with:</p>
    <ul>
      <li><strong>Service Providers:</strong> Third parties who provide services on our behalf, such
        as payment processors and delivery partners.
      </li>
      <li><strong>Legal Obligations:</strong> When required by law or in response to valid requests by
        public authorities.
      </li>
      <li><strong>Business Transfers:</strong> In connection with any merger, sale of company assets,
        financing, or acquisition of all or a portion of our business to another company.
      </li>
    </ul>
  </section>

  <section>
    <h2>Compliance with the POPI Act</h2>
    <p>We are committed to processing your personal information in accordance with the Protection of
      Personal Information Act, 2013 (POPI Act) in South Africa. This includes ensuring that your
      personal information is processed lawfully, fairly, and transparently. We will only collect
      information necessary for the purposes stated in this policy and will take reasonable steps to
      ensure that your personal information is accurate, complete, and up-to-date.</p>
  </section>

  <section>
    <h2>Security of Your Information</h2>
    <p>We use administrative, technical, and physical security measures to protect your personal
      information. While we have taken reasonable steps to secure the personal information you provide
      to us, please be aware that no security measures are perfect or impenetrable, and no method of
      data transmission can be guaranteed against interception or other misuse.</p>
  </section>

  <section>
    <h2>Data Retention</h2>
    <p>We will retain your personal information only for as long as is necessary for the purposes set out in
      this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
  </section>

  <section>
    <h2>Your Data Protection Rights</h2>
    <p>Depending on your location, you may have the following rights regarding your personal information:
    </p>
    <ul>
      <li>The right to access – You have the right to request copies of your personal information.
      </li>
      <li>The right to rectification – You have the right to request that we correct any information
        you believe is inaccurate or complete information you believe is incomplete.
      </li>
      <li>The right to erasure – You have the right to request that we erase your personal
        information, under certain conditions.
      </li>
      <li>The right to restrict processing – You have the right to request that we restrict the
        processing of your personal information, under certain conditions.
      </li>
      <li>The right to object to processing – You have the right to object to our processing of your
        personal information, under certain conditions.
      </li>
      <li>The right to data portability – You have the right to request that we transfer the data that
        we have collected to another organization, or directly to you, under certain conditions.
      </li>
    </ul>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of
      these rights, please contact us at our provided contact information.</p>
  </section>

  <section>
    <h2>Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact
      us at:</p>
    <p><strong>Email: </strong> <a href="mailto:infodesk@thenga-lula.co.za">infodesk@thenga-lula.co.za</a>
    </p>
    <p><strong>Phone:</strong> +27 65 103 3178</p>
    <button class="btn btn-sm btn-secondary" (click)="previewPdf()"><strong>Download Hard-Copy</strong></button>
  </section>

</div>
<app-footer *ngIf="!preview"></app-footer>
