<app-navbar></app-navbar>
<section class="section-padding errorpage">
    <div class="container">
        <div class="align-items-center">
            <div class="text-center">
                <div class="error-texts relative">
                    <h1>404</h1>
                    <h3>Ooops! That page doesn't exist!</h3>
                    <p>Seems like you've come to a dead-end.</p>
                    <a routerLink="/" class="btn-second btn-submit">Back to Home</a>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>