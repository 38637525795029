<app-navbar></app-navbar>

<!-- Blog -->
    <section class="our-articles bg-light-theme section-padding pt-0">
        <div class="blog-page-banner"></div>
        <div class="container-fluid">
            <div class="row">
                <aside class="col-lg-3">
                <app-blogleftsidebar></app-blogleftsidebar>
            </aside>
                <div class="col-lg-6 blog-inner clearfix">
                    <div class="main-box padding-20 full-width">
                        <div class="breadcrumb-wrpr">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/" class="text-light-black">Home</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Blog</li>
                            </ul>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-1.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-1.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Best chinese restaurants in Brooklyn</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-style-2">
                                            <img src="assets/img/blog/426x311/blog-3.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-4.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-style-2" class="text-light-black">15 Best Italian food you must try</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-style-2" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-2.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-3.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Tomatoes Stuffed with Foie and Chanterelles</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-4.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-1.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Chanterelle and Porcini Mushroom Recipes</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-5.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-2.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Best chinese restaurants in Brooklyn</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-6.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-3.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">15 Best Italian food you must try</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-7.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-4.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Tomatoes Stuffed with Foie and Chanterelles</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box mb-xl-20">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-8.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-1.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Chanterelle and Porcini Mushroom Recipes</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-9.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-4.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">Best chinese restaurants in Brooklyn</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="col-xl-6 col-lg-12 col-sm-6">
                                <article class="blog-services-wrapper main-box">
                                    <div class="post-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/426x311/blog-10.jpg" class="img-fluid full-width" alt="image">
                                        </a>
                                    </div>
                                    <div class="post-meta">
                                        <div class="author-img">
                                            <img src="assets/img/user/user-3.png" class="rounded-circle" alt="image">
                                        </div>
                                        <div class="author-meta">
                                            <h6 class="no-margin"><a routerLink="/blog-style-2" class="text-light-black">Charles C. Pruitt</a></h6>
                                            <p class="no-margin text-light-white"><a routerLink="/blog-style-2" class="text-light-white">Admin</a> | 25 January , 2022</p>
                                        </div>
                                    </div>
                                    <div class="post-content padding-20">
                                        <h5><a routerLink="/blog-details" class="text-light-black">15 Best Italian food you must try</a></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable...</p>
                                        <div class="blog-link-wrap"> <a routerLink="/blog-details" class="btn-first white-btn">Learn More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <aside class="col-lg-3">
                <app-blogrightsidebar></app-blogrightsidebar>
            </aside>
            </div>
        </div>
    </section>

    <app-footer></app-footer>