import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  showPdf = false;

  constructor() { }

  ngOnInit(): void {
  }

  dashboardRedirect(): void {
    window.location.href = 'https://portal.thenga-lula.co.za/';
  }

  togglePdf() {
    this.showPdf = !this.showPdf;
  }

}
