<!-- advertisement banner-->
<div class="row">
    <div class="col-12">
        <div class="banner-adv2 mb-xl-20">
            <img src="https://images.pexels.com/photos/2271106/pexels-photo-2271106.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                class="img-fluid full-width" alt="banner">
            <span class="text">Unlimited Free Delivery with us.</span>
            <span class="close-banner"></span>
            <div class="overlay overlay-bg"></div>
        </div>
    </div>
</div>
<!-- advertisement banner-->