import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {

    pdfSrc = 'https://commerce.fra1.cdn.digitaloceanspaces.com/documents/ThengaLulaPrivacyPolicy.pdf';

    preview = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    previewPdf() {
        this.preview = true;
    }

}
