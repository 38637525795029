<app-navbar></app-navbar>
<section class="banner-1 p-relative">
    <img src="https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        class="img-fluid full-width" alt="Banner">
    <div class="transform-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="content-wrapper bg-white padding-20">
                        <div class="content-box padding-tb-10">
                            <h5 class="text-light-black fw-700">Find info from app orders.</h5>
                            <label class="text-light-black fw-700">Track your order (order code usually starts with ORD)
                            </label>
                            <div class="input-group row">
                                <div class="input-group2 col-xl-8">
                                    <input type="search" class="form-control form-control-submit" placeholder="Enter Order Code">
                                    <div class="input-group-prepend">
                                        <button class="input-group-text text-light-green"><i
                                                class="fab fa-telegram-plane"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="input-group-append col-xl-4">
                                    <button class="btn-second btn-submit full-width" type="button" [disabled]="true">Track</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay overlay-bg"></div>
</section>
<!-- Browse by category -->
<!--<section class="browse-cat u-line section-padding">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12">-->
<!--                <div class="section-header-left">-->
<!--                    <h3 class="text-light-black header-title title">Browse by cuisine <span class="fs-14"><a-->
<!--                                routerLink="/restaurant">See all restaurant</a></span></h3>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-12">-->
<!--                <div class="category-slider swiper-container">-->
<!--                    <div class="swiper-wrapper">-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon icon-parent text-custom-white bg-light-green"> <i-->
<!--                                        class="fas fa-map-marker-alt"></i>-->
<!--                                </div> <span class="text-light-black cat-name">Brooklyn</span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-1.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Italian </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-2.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Thai </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-3.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Chinese </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-4.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Mexican </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-5.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Indian </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-6.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Lebanese </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-7.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">Japanese </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <a routerLink="/restaurant" class="categories">-->
<!--                                <div class="icon text-custom-white bg-light-green ">-->
<!--                                    <img src="assets/img/restaurants/125x125/cuisine-8.jpg" class="rounded-circle"-->
<!--                                        alt="categories">-->
<!--                                </div> <span class="text-light-black cat-name">American </span>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Add Arrows &ndash;&gt;-->
<!--                    <div class="swiper-button-next"></div>-->
<!--                    <div class="swiper-button-prev"></div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<!-- Browse by category -->
<!-- your previous order -->
<!--<section class="recent-order section-padding">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12">-->
<!--                <div class="section-header-left">-->
<!--                    <h3 class="text-light-black header-title title">Your previous orders <span class="fs-14"><a-->
<!--                                routerLink="/orderdetails">See all past orders</a></span></h3>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6 col-sm-6">-->
<!--                <div class="product-box mb-md-20">-->
<!--                    <div class="product-img">-->
<!--                        <a routerLink="/restaurant">-->
<!--                            <img src="assets/img/restaurants/255x104/order-1.jpg" class="img-fluid full-width"-->
<!--                                alt="product-img">-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="product-caption">-->
<!--                        <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Chilli Chicken-->
<!--                                Pizza</a></h6>-->
<!--                        <p class="text-light-white">Big Bites, Brooklyn</p>-->
<!--                        <div class="product-btn">-->
<!--                            <a routerLink="/orderdetails"-->
<!--                                class="btn-first white-btn full-width text-light-green fw-600">Track Order</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6 col-sm-6">-->
<!--                <div class="product-box mb-md-20">-->
<!--                    <div class="product-img">-->
<!--                        <a routerLink="/restaurant">-->
<!--                            <img src="assets/img/restaurants/255x104/order-2.jpg" class="img-fluid full-width"-->
<!--                                alt="product-img">-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="product-caption">-->
<!--                        <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Hakka-->
<!--                                Noodles</a></h6>-->
<!--                        <p class="text-light-white">Flavor Town, Brooklyn</p>-->
<!--                        <div class="product-btn">-->
<!--                            <a routerLink="/orderdetails"-->
<!--                                class="btn-first white-btn full-width text-light-green fw-600">Track Order</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6 col-sm-6">-->
<!--                <div class="product-box mb-md-20">-->
<!--                    <div class="product-img">-->
<!--                        <a routerLink="/restaurant">-->
<!--                            <img src="assets/img/restaurants/255x104/order-3.jpg" class="img-fluid full-width"-->
<!--                                alt="product-img">-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="product-caption">-->
<!--                        <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Vegan Burger-->
<!--                            </a></h6>-->
<!--                        <p class="text-light-white">Great Burger, Brooklyn</p>-->
<!--                        <div class="product-btn">-->
<!--                            <a routerLink="/orderdetails"-->
<!--                                class="btn-first white-btn full-width text-light-green fw-600">Track Order</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-md-6 col-sm-6">-->
<!--                <div class="product-box mb-md-20">-->
<!--                    <div class="product-img">-->
<!--                        <a routerLink="/restaurant">-->
<!--                            <img src="assets/img/restaurants/255x104/order-4.jpg" class="img-fluid full-width"-->
<!--                                alt="product-img">-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="product-caption">-->
<!--                        <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Sticky Date-->
<!--                                Cake</a></h6>-->
<!--                        <p class="text-light-white">Smile N’ Delight, Brooklyn</p>-->
<!--                        <div class="product-btn">-->
<!--                            <a routerLink="/orderdetails"-->
<!--                                class="btn-first white-btn full-width text-light-green fw-600">Track Order</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<!-- your previous order -->
<div class="container">
    <!-- advertisement banner-->
<!--    <div class="row">-->
<!--        <div class="col-12">-->
<!--            <app-advertisementbanner></app-advertisementbanner>-->
<!--        </div>-->
<!--    </div>-->
    <!-- advertisement banner-->
</div>

<!-- Explore collection -->
<section class="ex-collection section-padding">
    <div class="container">
        <div class="thumb-img text-center">
            <h3>Explore on our mobile app</h3>
            <img class="downloadApp" src="assets/img/downloadApp.png" alt="thumb-img">
        </div>
    </div>
</section>
<!-- Explore collection -->

<app-footer></app-footer>
