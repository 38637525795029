<!-- footer -->
<div class="footer-top section-padding bg-black">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                <div class="icon-box"> <span class="text-light-green"><i class="flaticon-credit-card-1"></i></span>
                    <span class="text-custom-white">100% Payment<br>Secured</span>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                <div class="icon-box"> <span class="text-light-green"><i class="flaticon-wallet-1"></i></span>
                    <span class="text-custom-white">Supports<br>multiple payments</span>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                <div class="icon-box"> <span class="text-light-green"><i class="flaticon-help"></i></span>
                    <span class="text-custom-white">24 hours / 7 days<br>Support</span>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-6">
                <div class="icon-box"> <span class="text-light-green"><i class="flaticon-truck"></i></span>
                    <span class="text-custom-white">Competitive Delivery<br></span>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-6">
                <div class="icon-box"> <span class="text-light-green"><i class="flaticon-guarantee"></i></span>
                    <span class="text-custom-white">Best Price<br>Guaranteed</span>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 col-6">
                <div class="icon-box"> <span class="text-light-green"><i class="flaticon-app-file-symbol"></i></span>
                    <span class="text-custom-white">Mobile<br>Friendly</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="copyright bg-black">
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-lg-4">
                    <div class="payment-logo mb-md-20"> <span class="text-light-white fs-14 mr-3">We accept</span>
                        <div class="payemt-icon">
                            <img src="assets/img/paymentMethods/ApplePay.png" alt="#">
                            <img src="assets/img/paymentMethods/DinersClubLogo.jpg" alt="#">
                            <img src="assets/img/paymentMethods/instantEFT.jpg" alt="#">
                            <img src="assets/img/paymentMethods/masterCard.jpg" alt="#">
                            <img src="assets/img/paymentMethods/RCS.jpg" alt="#">
                            <img src="assets/img/paymentMethods/Visa.jpg" alt="#">
                        </div>
                    </div>
                </div> -->
            <div class="col-lg-4 text-center medewithlove align-self-center">
                <div class="copyright-text"> <span class="text-light-white">&copy; Thenga-Lula - {{ year }} | All Right
                        Reserved</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- footer -->