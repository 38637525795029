<div class="inner-wrapper">
    <div class="container-fluid no-padding">
        <div class="row no-gutters">
            <div class="col-md-6">
                <div class="main-banner">
                    <img src="assets/img/banner/banner-2.jpg" class="img-fluid full-width main-img" alt="banner">
                    <div class="overlay-2 main-padding">
                        <img src="assets/img/logo-2.jpg" class="img-fluid" alt="logo">
                    </div>
                    <img src="assets/img/banner/burger.png" class="footer-img" alt="footer-img">
                </div>
            </div>
            <div class="col-md-6">
                <div class="section-2 main-page main-padding">
                    <div class="top-nav">
                        <h5><a routerLink="/homepage2" class="text-light-green fw-700">Explore</a></h5>
                        <h5><a routerLink="/login" class="text-light-green fw-700">Sign in</a></h5>
                    </div>
                    <div class="login-box">
                        <div class="col-12">
                            <h1 class="text-light-black fw-700">Quickmunch food delivery every time</h1>
                            <div class="input-group row">
                                <div class="input-group2 col-xl-8">
                                    <input type="search" class="form-control form-control-submit"
                                        placeholder="Enter street address or zip code"
                                        value="1246 57th St, Brooklyn, NY, 11219">
                                    <div class="input-group-prepend">
                                        <button class="input-group-text text-light-green"><i
                                                class="fab fa-telegram-plane"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="input-group-append col-xl-4">
                                    <button class="btn-second btn-submit full-width" type="button">Find food</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="offer">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header no-padding">
                <div class="offer-content">
                    <h2 class="text-custom-white fw-700">Get $12 off <small class=" fw-700">your order*</small></h2>
                    <p class="text-custom-white">As an added treat, enjoy <strong>free delivery</strong> from
                        <br>select restaurants automatically applied at checkout
                    </p>
                </div>
                <button type="button" class="close" data-dismiss="modal">X</button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="text-light-white fs-14">Email</label>
                                <input type="email" name="#" class="form-control form-control-submit"
                                    placeholder="Email I'd">
                            </div>
                            <div class="form-group">
                                <label class="text-light-white fs-14">ZIP Code</label>
                                <input type="number" name="#" class="form-control form-control-submit"
                                    placeholder="10421">
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn-second btn-submit full-width">Save $12 on your first
                                    order</button>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn-second btn-default full-width text-light-green fw-700"
                                    data-dismiss="modal">Dismiss</button>
                            </div>
                            <div class="text-center"> <span class="text-light-black fs-12">*Valid on first order only,
                                    for one-time use, subject to quickmunch’s verification. Additional terms may apply.
                                    By signing up, you agree to receive marketing and promotional emails and
                                    communications form quickmunch</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>